import axios from './CustomAxios';
import { getCustomAlert } from './CustomAlertUtil';

/******************************************* APIs To Update Data *************************************************/

export async function onUpdateFCData(product, getDataFunction, isAdd) {
  const url = `stocks/forced-category/?family=${product.family}&&subFamily=${product.subFamily}&&country=${product.country}&&toAdd=${isAdd}`;
  try {
    await axios.put(url).then((response) => {
      if (response.status === 200) {
        getCustomAlert('success', response);
      }
      if (!isAdd) {
        getDataFunction();
      }
    });
  } catch (error) {
    getCustomAlert('error', error);
  }
}

export async function onUpdateDailyAverageSales(reqBody, closeModal, getDataFunction) {
  const url = `stocks/daily-average-sales`;
  try {
    await axios.put(url, reqBody).then((response) => {
      if (response.status === 200) {
        getCustomAlert('success', response);
      }
      closeDialog(closeModal, getDataFunction);
    });
  } catch (error) {
    getCustomAlert('error', error);
  }
}

export async function onUpdateStockBuffer(reqBody, closeModal, getDataFunction) {
  const url = `stocks/stock-buffer`;
  try {
    await axios.put(url, reqBody).then((response) => {
      if (response.status === 200) {
        getCustomAlert('success', response);
      }
      closeDialog(closeModal, getDataFunction);
    });
  } catch (error) {
    getCustomAlert('error', error);
  }
}

export async function onUpdateItemMaster(reqBody, closeModal, getDataFunction) {
  const url = `stocks/item-master`;
  try {
    await axios.put(url, reqBody).then((response) => {
      if (response.status === 200) {
        getCustomAlert('success', response);
      }
      closeDialog(closeModal, getDataFunction);
    });
  } catch (error) {
    getCustomAlert('error', error);
  }
}

export async function onUpdateManualStock(reqBody, allStores, closeModal, setLoader) {
  const url = `stocks/manual-stock/?allStores=${allStores}`;
  try {
    setLoader(true)
    await axios.post(url, reqBody).then((response) => {
      if (response.status === 200) {
        getCustomAlert('success', response);
      }
      setLoader(false)
      closeModal(false);
    });
  } catch (error) {
    getCustomAlert('error', error);
    setLoader(false);
  }
}

/******************************************* APIs To Bulk Update Data *************************************************/

export async function onBulkUpdateData(urlPath,file, allStores, stores,country, setLoader, setOpenBulkUpdate) {
  const formData = new FormData();
  formData.append('file', file);
  const url = `stocks/${urlPath}/file/?country=${country}&stores=${stores}&allStores=${allStores}`;
  try {
    const response = await axios.post(url, formData);
    if (response.data.success) {
      setLoader(false);
      getCustomAlert('success', response);
    }
    setOpenBulkUpdate(false);
  } catch (error) {
    setLoader(false);
    getCustomAlert('error', error);
    setOpenBulkUpdate(false);
  }
}

export async function getCsvforItemMasterForStore(store, country, setLoader, setRes) {
  const url = `stocks/item-master-csv/store/?store=${store}&country=${country}`;
  await axios.get(url).then((response) => {
    if (response.status === 200) {
      setLoader(false);
      const res = response.data.data;
      setRes(res);
    }
  });
}

function closeDialog(closeModal, getDataFunction) {
  closeModal(false);
  getDataFunction();
}

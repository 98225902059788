import { React, useState } from 'react';
import { onUpdateStockBuffer } from '../../base/utils/apiUtil';
import CustomAlert from '../../base/CustomAlert/CustomAlert';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import '../Update.css';
import StockBufferPopUp from './StockBufferPopUp';
import { getCustomAlert } from '../../base/utils/CustomAlertUtil';
import AddCommonComponent from '../CommonComponents/AddCommonComponent';

const AddStockBuffer = ({ family, subFamily, country, getData, closeModal }) => {
  const [productDetail, setProductDetail] = useState({
    stores: '',
    stockBuffer: 0,
  });
  const [allStores, setAllStores] = useState(false);

  const buildData = async () => {
    try {
      if (isNaN(productDetail.stockBuffer)) {
        CustomAlert('error', 'Daily Average Sale or Buffer can only be a Number');
      } else if (!allStores && productDetail.stores === '') {
        CustomAlert('error', 'Stores Missing');
      } else {
        const reqBody = {
          family: family,
          subFamily: subFamily,
          country: country,
          store: allStores === true ? 'ALLSTORES' : productDetail.stores,
          stockBuffer: productDetail.stockBuffer,
        };
        await onUpdateStockBuffer(reqBody, closeModal, getData);
      }
    } catch (error) {
      getCustomAlert(error);
    }
  };

  return (
    <div className="main_outer_div">
      <div className="main_inner_div">
        <div className="modalBackground">
          <div className="modalContainer">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Close">
                <button className="cross_button" onClick={() => closeModal(false)}>
                  X
                </button>
              </Tooltip>
            </div>

            <div className="centerHeader">
              <h3>Add New Stock Buffer</h3>
              <label style={{ fontStyle: 'italic' }}>Provide Stores as Comma Separated</label>
            </div>
            <div className="itemUpdate_body">
              <form className="itemUpdate_form">
                <div className="div_style">
                  <label>Family :</label>
                  <label>{family}</label>
                </div>
                <div className="div_style">
                  <label>Sub Family :</label>
                  <label>{subFamily}</label>
                </div>
                <AddCommonComponent
                  allStores={allStores}
                  productDetail={productDetail}
                  setProductDetail={setProductDetail}
                  setAllStores={setAllStores}
                />
                <StockBufferPopUp
                  productDetail={productDetail}
                  setProductDetail={setProductDetail}
                />
              </form>
            </div>
            <div className="footer">
              <Button id="getDataBtn" onClick={buildData.bind()} variant="contained">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStockBuffer;

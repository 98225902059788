import React, { useState } from 'react';
import axios from '../../base/utils/CustomAxios';
import CustomAlert from '../../base/CustomAlert/CustomAlert';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { userHaveReadAccessOnly } from '../../base/utils/util';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { getCustomAlert } from '../../base/utils/CustomAlertUtil';
import AddManualStock from '../../Components/ManualStockComp/AddManualStock';
import ManualStockTableComponent from '../../Components/ManualStockComp/ManualStockTableComponent';
import '../Screens.css';

const ManualStock = ({ country }) => {
  const [loader, setLoader] = useState(false);
  const [result, setResult] = useState('');
  const [productCode, setProductCode] = useState('');
  const [store, setStore] = useState('');
  const [openManualStockAdd, setOpenManualStockAdd] = useState(false);

  async function getManualStockCampaignsData() {
    try {
      setResult('');
      setLoader(true);
      if (productCode === '' || store === '' || country === 'Country') {
        CustomAlert('error', 'Please Enter Product Code, Store And Choose a Country');
      } else if (isNaN(productCode)) {
        CustomAlert('error', 'ProductCode can only be a number');
      } else {
        const res = await axios.get(
          `stocks/manual-stock/?productCode=${productCode}&&store=${store}&&country=${country}`
        );
        if (res.data.success) {
          setResult(res.data.data);
        }
      }
      setLoader(false);
    } catch (error) {
      setResult('');
      getCustomAlert('error', error);
      setLoader(false);
    }
  }

  const haveReadAccessOnly = userHaveReadAccessOnly();

  return (
    <>
      <div className="action_outer_div">
        <div className="action_buttons_outer">
          <div className="action_buttons_inner">
            <input
              id="input_1"
              className="input_1"
              name="productCode"
              value={productCode}
              placeholder="Product Code"
              onChange={(e) => setProductCode(e.target.value)}
            />

            <input
              id="input_2"
              className="input_2"
              name="store"
              value={store}
              placeholder="Store"
              onChange={(e) => setStore(e.target.value)}
            />

            <Button
              id="getDataBtn"
              onClick={getManualStockCampaignsData.bind()}
              variant="contained"
              startIcon={<SearchIcon />}
            >
              Search
            </Button>

            {haveReadAccessOnly === false ? (
              <Button
                onClick={() => {
                  setOpenManualStockAdd(true);
                }}
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add Manual Stock
              </Button>
            ) : null}
          </div>
        </div>

        {openManualStockAdd && (
          <div className="open_add_inner_div">
            <AddManualStock
              setOpenManualStockAdd={setOpenManualStockAdd}
              country={country}
              style={{ position: 'absolute' }}
            />
          </div>
        )}
        {result?.length > 0 ? (
          <ManualStockTableComponent data={result} country={country} getManualStockCampaignsData={getManualStockCampaignsData} />
        ) : (
          loader && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <CircularProgress />
            </Box>
          )
        )}
      </div>
    </>
  );
};

export default ManualStock;

import React, { useState } from 'react';
import axios from '../../base/utils/CustomAxios';
import CustomAlert from '../../base/CustomAlert/CustomAlert';
import '../Screens.css';
import ForcedCategoryTable from '../../Components/ForcedCategoryComp/ForcedCategoryTable';
import { userHaveReadAccessOnly } from '../../base/utils/util';
import AddForcedCategory from '../../Components/ForcedCategoryComp/AddForcedCategory';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getCustomAlert } from '../../base/utils/CustomAlertUtil';

const ForcedCategory = ({ country }) => {
  const [result, setResult] = useState('');
  const [openAdd, setOpenAdd] = useState(false);
  const [loader, setLoader] = useState(false);

  const haveReadAccessOnly = userHaveReadAccessOnly();

  async function getData() {
    try {
      setResult('');
      setLoader(true);
      if (country === 'Country') {
        CustomAlert('error', 'Please choose a country');
      } else {
        const res = await axios.get(`stocks/forced-category?country=${country}`);
        if (res.data.success) {
          setResult(res.data.data);
        }
      }
      setLoader(false);
    } catch (error) {
      setResult('');
      getCustomAlert('error', error);
      setLoader(false);
    }
  }

  return (
    <>
      <div>
        <div className="action_outer_div">
          <div className="action_buttons_inner">
            <input className="input_2" type="text" value={country} readOnly={true} />

            <Button
              id="getDataBtn"
              onClick={() => {
                getData().catch(error => {
                  console.error(error);
                });
              }}
              variant="contained"
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
            {haveReadAccessOnly === false ? (
              <Button onClick={() => setOpenAdd(true)} variant="contained" startIcon={<AddIcon />}>
                Add
              </Button>
            ) : null}
          </div>
        </div>

        <div className="open_add_outer_div">
          {openAdd && (
            <div className="open_add_inner_div">
              <AddForcedCategory
                country={country}
                closeModal={setOpenAdd}
                getData={getData}
                style={{ position: 'absolute' }}
              />
            </div>
          )}
        </div>

        {result?.length > 0 ? (
          <ForcedCategoryTable data={result} getData={getData} />
        ) : (
          loader && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )
        )}
      </div>
    </>
  );
};

export default ForcedCategory;

import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const UpdateCommonComponent = ({
  PopUpComponent,
  product,
  productDetail,
  setProductDetail,
  buildData,
  closeModal,
  bufferFlag
}) => {
  return (
    <div className="main_outer_div">
      <div className="main_inner_div">
        <div className="modalBackground">
          <div className="modalContainer">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Close">
                <button className="cross_button" onClick={() => closeModal(false)}>
                  X
                </button>
              </Tooltip>
            </div>

            <div className="centerHeader">
              <h3>Provide the Data you want to update</h3>
            </div>
            <div className="itemUpdate_body">
              <form className="itemUpdate_form">
                {bufferFlag === true ? (
                  <>
                    <div className="div_style">
                      <label>Family :</label>
                      <label style={{ marginRight: '20px' }}>{product.family}</label>
                    </div>
                    <div className="div_style">
                      <label>Sub Family :</label>
                      <label style={{ marginRight: '20px' }}>{product.subFamily}</label>
                    </div>
                  </>
                ) : null}
                <div className="div_style">
                  <label>Store :</label>
                  <label style={{ marginRight: '20px' }}>{product.store}</label>
                </div>
                <PopUpComponent
                  flag={true}
                  productDetail={productDetail}
                  setProductDetail={setProductDetail}
                />
              </form>
            </div>
            <div className="footer">
              <Button id="getDataBtn" onClick={buildData.bind()} variant="contained" size="small">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCommonComponent;

import React from 'react';
import { getStyledTableCell, getStyledTableRow } from '../../base/utils/util';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';

const TableCommonRowComponent = ({
  key,
  product,
  fileNamePrefix,
  haveReadAccessOnly,
  openUpdate,
  setOpenUpdate,
  setUpdateItem,
}) => {
  const StyledTableCell = getStyledTableCell();

  const StyledTableRow = getStyledTableRow();

  return (
    <>
      <StyledTableRow key={product.id}>
        <StyledTableCell align="center">{product.store}</StyledTableCell>
        <StyledTableCell align="center">
          {fileNamePrefix === 'DailyAverageSales' ? product.dailyAverageSales : product.stockBuffer}
        </StyledTableCell>
        {fileNamePrefix === 'DailyAverageSales' ? (
          <StyledTableCell align="center">{product.addBuffer}</StyledTableCell>
        ) : null}
        {haveReadAccessOnly === false ? (
          <StyledTableCell align="center">
            <Button
              onClick={() => {
                setOpenUpdate(!openUpdate);
                setUpdateItem(product);
              }}
              variant="outlined"
            >
              <EditIcon />
            </Button>
          </StyledTableCell>
        ) : null}
      </StyledTableRow>
    </>
  );
};

export default TableCommonRowComponent;

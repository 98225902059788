import React, { useState } from 'react';
import '../Update.css';
import { onUpdateStockBuffer } from '../../base/utils/apiUtil';
import CustomAlert from '../../base/CustomAlert/CustomAlert';
import { getCustomAlert } from '../../base/utils/CustomAlertUtil';
import StockBufferPopUp from './StockBufferPopUp';
import UpdateCommonComponent from '../CommonComponents/UpdateCommonComponent';

const UpdateStockBuffer = ({ product, closeModal, getData }) => {
  const [productDetail, setProductDetail] = useState({
    stockBuffer: product.stockBuffer,
  });

  const buildData = async () => {
    try {
      if (
        isNaN(productDetail.stockBuffer)
      ) {
        CustomAlert('error', 'Stock Buffer can only be a Number');
      } else {
        const reqBody = {
          family: product.family,
          subFamily: product.subFamily,
          country: product.country,
          store: product.store,
          stockBuffer: productDetail.stockBuffer,          
        };
        await onUpdateStockBuffer(reqBody, closeModal, getData).catch();
      }
    } catch (error) {
      getCustomAlert(error);
    }
  };
  
  return (
    <>
      <UpdateCommonComponent
        PopUpComponent={StockBufferPopUp}
        product={product}
        productDetail={productDetail}
        setProductDetail={setProductDetail}
        buildData = {buildData}
        closeModal = {closeModal}
        bufferFlag = {true}
      />
    </>
  );

};

export default UpdateStockBuffer;

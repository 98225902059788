import { makeStyles } from "@material-ui/core";

const toastStyles = makeStyles({
  toastMessageContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: { marginRight: "8px" },
});

export default toastStyles;
import React, { useState } from 'react';
import CustomAlert from '../../base/CustomAlert/CustomAlert';
import { getCustomAlert } from '../../base/utils/CustomAlertUtil';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { onUpdateManualStock } from '../../base/utils/apiUtil';
import styles from '../CommonComponents/BulkUpdateCommonComponent.module.css'

const UpdateManualStock = ({ manualStockProduct, setOpenManualStockUpdate, country,getManualStockCampaignsData }) => {
  const [loader, setLoader] = useState(false);
  const [productDetail, setProductDetail] = useState({
    quantity: manualStockProduct.quantity,
    isExpired: false,
  });

  const buildData = async () => {
    try {
      if (isNaN(productDetail.quantity)) {
        CustomAlert('error', 'Quantity can only be a Number');
      } else {
        const reqBody = {
          productCode: manualStockProduct.productCode,
          store: manualStockProduct.store,
          quantity: productDetail.quantity,
          country: country,
          isExpired: productDetail.isExpired,
          beginAt: manualStockProduct.beginAt,
          endAt: manualStockProduct.endAt,
        };
        await onUpdateManualStock(
          reqBody,
          false,
          setOpenManualStockUpdate,
          setLoader
        ).catch();
        getManualStockCampaignsData();
      }
    } catch (error) {
      getCustomAlert(error);
    }
  };

  return (
    <div className={styles.main_outer_div}>
      <div className={styles.main_inner_div}>
        <div className={styles.modalBackground}>
          <div className={styles.modalContainer}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Close">
                <button className="cross_button" onClick={() => setOpenManualStockUpdate(false)}>
                  X
                </button>
              </Tooltip>
            </div>

            <div className={styles.centerHeader}>
              <h3>Manual Stock Update Details</h3>
            </div>
            <div className={styles.itemUpdate_body}>
              <form className={styles.itemUpdate_form}>
                <div className={styles.manual_div_style}>
                  <label>Product Code :</label>
                  <label>{manualStockProduct.productCode}</label>
                </div>
                
                  <div className={styles.manual_div_style}>
                    <label>Store:</label>
                    <label>{manualStockProduct.store}</label>
                  </div>

                <div className={styles.manual_div_style}>
                  <label>Quantity :</label>
                  <input
                    id="das_input"
                    className={styles.manual_input}
                    type="number"
                    name="quantity"
                    value={productDetail.quantity}
                    onChange={(e) =>
                      setProductDetail({ ...productDetail, quantity: e.target.value })
                    }
                  />
                </div>

                <div className={styles.manual_div_style}>
                  <label>Delete:</label>
                  <input
                    id="allStoresInput"
                    className={styles.manual_stock_allStore_checkbox}
                    type="checkbox"
                    name="das_all_stores"
                    checked={productDetail.isExpired}
                    value={productDetail.isExpired}
                    onChange={(e) =>
                      setProductDetail({
                        ...productDetail,
                        isExpired: !productDetail.isExpired,
                      })
                    }
                  />
                </div>

                <div className={styles.manualStock_footer}>
                  <Button
                    className={styles.manual_stock_submit_btn}
                    id="getDataBtn"
                    onClick={buildData.bind()}
                    variant="contained"
                    size="small"
                  >
                    Submit
                  </Button>
                </div>

                {loader && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <CircularProgress />
                  </Box>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateManualStock;

import React, { useEffect, useState } from 'react';
import Login from './Common/login.jsx';
import './App.css';
import axios from './base/utils/CustomAxios';
import CustomAlert from './base/CustomAlert/CustomAlert';
import jwt_decode from 'jwt-decode';
import { useMsal } from '@azure/msal-react';
import { callMsGraph, getUserPhoto } from './graph';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import MainScreen from './Screens/MainScreen/MainScreen.jsx';

const App = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState('');
  const [profileimg, setProfileimg] = useState(null);
  const [refreshUserData, setRefreshUserData] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(accounts.length > 0);

  async function logout() {
    localStorage.clear();
    await instance.logoutRedirect({
      account: accounts[0],
      postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    });
  }

  useEffect(() => {
    if (!user && inProgress === InteractionStatus.None) {
      const tokenRequest = {
        scopes: ['user.read'],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(tokenRequest)
        .then((tokenResponse) => {
          callMsGraph(tokenResponse.accessToken)
            .then((resp) => {
              getUserPhoto(tokenResponse.accessToken, resp.userPrincipalName, resp.id)
                .then((respdata) => {
                  if (respdata !== null) {
                    setProfileimg(URL.createObjectURL(respdata));
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });

          // Acquire token silent success
          let idToken = tokenResponse.idTokenClaims;
          setUserDetails(idToken, tokenResponse);
          localStorage.setItem('accessToken', tokenResponse.accessToken);
          setToken().catch((error) => {
            console.log(error);
          });
        })
        .catch((error) => {
          handleAuthError(error, tokenRequest);
          console.log(error);
          // Handle error appropriately
        });
    }
  }, [instance, accounts, inProgress, user]);

  useEffect(() => {
    const handleInvalidToken = async (e) => {
      if (e.key === 'usertoken' && e.oldValue && !e.newValue) {
        try {
          await instance.logoutRedirect();
        } catch (error) {
          console.error(error);
        }
      }
    };
  
    const handleStorageEvent = (e) => {
      handleInvalidToken(e);
    };
  
    window.addEventListener('storage', handleStorageEvent);
  
    return function cleanup() {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [instance]);
  

  const setToken = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      const isExpired = isTokenExpired(authToken);
      if (isExpired) {
        await getAuthorizationToken(accessToken);
      }
    } else {
      await getAuthorizationToken(accessToken);
    }
  };

  async function getAuthorizationToken(accessToken) {
    const config = { headers: { token: accessToken } };
    const url = `${process.env.REACT_APP_API_URL}auth/signin`;
    await axios
      .post(url, {}, config)
      .then(({ data, status }) => {
        if (status === 200) {
          const { token, roleName, countryCodes } = data.data;
          localStorage.setItem('authToken', token);
          localStorage.setItem('roleName', roleName);
          const countries = [];
          countryCodes.map((c) => countries.push(c.countryCode));
          localStorage.setItem('countries', countries);
          setIsLoggedIn(true);
          setRefreshUserData(true);
        } else {
          CustomAlert('error', 'You are Not Authorized!');
          logout();
        }
      })
      .catch((error) => {
        // Handle promise rejection or error
        console.log(error);
        logout();
      });
  }

  function setUserDetails(idToken, tokenResponse) {
    setUser(idToken);
    const userName = localStorage.getItem('userName');
    if (userName) {
      setUserName(userName);
    } else {
      localStorage.setItem('userName', tokenResponse.account.name);
      setUserName(localStorage.getItem('userName'));
    }
  }

  const isTokenExpired = (token) => {
    const decoded = jwt_decode(token);
    const utcSeconds = decoded.exp;
    const expiryDate = new Date(0);
    expiryDate.setUTCSeconds(utcSeconds);
    const currDate = new Date();
    return currDate > expiryDate;
  };

  function handleAuthError(error, tokenRequest) {
    if (error instanceof InteractionRequiredAuthError) {
      instance
        .acquireTokenPopup(tokenRequest)
        .then(function (tokenResponse) {
          // Acquire token interactive success
          let idToken = tokenResponse.idToken;
          setUser(idToken);
        })
        .catch(function (error) {
          // Acquire token interactive failure
          console.log(error);
        });
    }
  }

  if (accounts.length > 0 && isLoggedIn) {
    return (
      <MainScreen userName={userName} profileImg={profileimg} refreshUserData={refreshUserData} />
    );
  } else
    return (
      <>
        <Login />
      </>
    );
};

export default App;

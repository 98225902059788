import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import './Vertical.css';
const VerticalNavbar = ({ setFlag, logout }) => {
  const [getvalue, setValue] = useState(false);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <button
          id="valBtn"
          onClick={() => {
            setValue(!getvalue);
          }}
        >
          <MenuIcon />
        </button>
        <div
          className="button_div"
          style={{
            transform: `${!getvalue ? 'translateY(-900px)' : 'translateY(2px)'}`,
          }}
        >
          <button
            id="hFlagBtn"
            className="hover vertical_button"
            onClick={() => {
              setFlag({ hFlag: true });
              setValue(!getvalue);
            }}
          >
            Item Master
          </button>

          <button
            id="dasFlagBtn"
            className="hover vertical_button"
            onClick={() => {
              setFlag({ DASFlag: true });
              setValue(!getvalue);
            }}
          >
            Daily Average Sales
          </button>

          <button
            id="fcFlagBtn"
            className="hover vertical_button"
            onClick={() => {
              setFlag({ FCFlag: true });
              setValue(!getvalue);
            }}
          >
            Forced Category
          </button>

          <button id="logOutBtn" className="hover vertical_button" onClick={() => logout()}>
            Log Out
          </button>
        </div>
      </div>
    </>
  );
};

export default VerticalNavbar;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import '../Update.css';
import CustomAlert from '../../base/CustomAlert/CustomAlert';
import { getCustomAlert } from '../../base/utils/CustomAlertUtil';
import { onUpdateItemMaster } from '../../base/utils/apiUtil';

const UpdateItemMaster = ({ item, getData, setOpenEditBox }) => {
  const [status, setStatus] = useState(item.currentStatus);
  const [isFood, setIsFood] = useState(item.isFood);

  const buildData = async () => {
    try {
      if (item.store === '' || status === '') {
        CustomAlert('warn', 'Store or Status Missing');
      } else {
        const reqBody = {
          productCode: item.productCode,
          country: item.country,
          store: item.store,
          status: status,
          isFood: isFood,
        };
        await onUpdateItemMaster(reqBody, setOpenEditBox, getData);
      }
    } catch (error) {
      getCustomAlert(error);
    }
  };

  return (
    <div className="main_outer_div">
      <div className="main_inner_div_cs">
        <div className="modalBackground">
          <div className="modalContainer">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Close">
                <button className="cross_button" onClick={() => setOpenEditBox(false)}>
                  X
                </button>
              </Tooltip>
            </div>

            <div className="centerHeader">
              <h4>Provide the Data you want to update</h4>
            </div>
            <div className="itemUpdate_body">
              <form className="itemUpdate_form">
                <div className="div_style">
                  <label>Store :</label>
                  <label id="store" className="store_label">
                    {item.store}
                  </label>
                </div>

                <div className="div_style">
                  <label style={{ fontSize: '18px' }}>Min Stock Buffer:</label>
                  <label id="store" className="store_label">
                    {item.minStockBuffer}
                  </label>
                </div>

                <div className="div_style">
                  <label>Status : </label>
                  <Select
                    id="status"
                    style={{ height: '35px', width: '100px' }}
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <MenuItem value="forceInStock">Force In Stock</MenuItem>
                    <MenuItem value="notSpecified">Not Specified</MenuItem>
                  </Select>
                </div>

                <div className="div_style">
                  <label>Product Type : </label>
                  <Select
                    style={{ height: '35px', width: '100px' }}
                    value={isFood}
                    onChange={(e) => {
                      setIsFood(e.target.value);
                    }}
                  >
                    <MenuItem value="true">Food</MenuItem>
                    <MenuItem value="false">Non Food</MenuItem>
                  </Select>
                </div>
              </form>
            </div>
            <div className="footer">
              <Button id="getDataBtn" onClick={buildData.bind()} variant="contained" size="small">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateItemMaster;

import React, { useState } from 'react';
import '../Table.css';
import {
  getStyledTableCell,
  getStyledTableRow,
  userHaveReadAccessOnly,
} from '../../base/utils/util';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import UpdateManualStock from './UpdateManualStock';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

const ManualStockTableComponent = ({ data,country,getManualStockCampaignsData }) => {
  const [openUpdate, setOpenUpdate] = useState(false);
  const [updateItem, setUpdateItem] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const haveReadAccessOnly = userHaveReadAccessOnly();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const StyledTableCell = getStyledTableCell();
  const StyledTableRow = getStyledTableRow();

  return (
    <>
      <div className="table-div">
        <div className="table_inner_div">
        {openUpdate && (
            <div className="open_popup">
              <UpdateManualStock
                manualStockProduct = {updateItem}
                setOpenManualStockUpdate = {setOpenUpdate}
                country={country}
                getManualStockCampaignsData = {getManualStockCampaignsData}
                style={{ position: 'absolute' }}
              />
            </div>
          )}
          <div id="table">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 450 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell
                        sx={{
                          fontFamily: 'Montserrat!important',
                          fontWeight: '600!important',
                          fontSize: '15px',
                        }}
                        align="center"
                      >
                        Begin At
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          fontFamily: 'Montserrat!important',
                          fontWeight: '600!important',
                          fontSize: '15px',
                        }}
                        align="center"
                      >
                        End At
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          fontFamily: 'Montserrat!important',
                          fontWeight: '600!important',
                          fontSize: '15px',
                        }}
                        align="center"
                      >
                        Quantity
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          fontFamily: 'Montserrat!important',
                          fontWeight: '600!important',
                          fontSize: '15px',
                        }}
                        align="center"
                      >
                        Status
                      </StyledTableCell>
                      {haveReadAccessOnly === false ? (
                        <StyledTableCell
                          sx={{
                            fontFamily: 'Montserrat!important',
                            fontWeight: '600!important',
                            fontSize: '15px',
                          }}
                          align="center"
                        >
                          Edit
                        </StyledTableCell>
                      ) : null}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.length > 0 &&
                      data
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((product) => {
                          console.log("product = >",product);
                          return (
                            <StyledTableRow key={product.id}>
                              <StyledTableCell align="center">
                                {product.beginAt.substring(0, 19)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {product.endAt.substring(0, 19)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {product.quantity}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {product.campaignStatus}
                              </StyledTableCell>
                              {haveReadAccessOnly === false ? (
                                <StyledTableCell align="center">
                                  <Button
                                    onClick={() => {
                                      setOpenUpdate(!openUpdate);
                                      setUpdateItem(product);
                                    }}
                                    variant="outlined"
                                  >
                                    <EditIcon />
                                  </Button>
                                </StyledTableCell>
                              ) : null}
                            </StyledTableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManualStockTableComponent;

import { React, useState } from 'react';
import '../Update.css';
import { onUpdateDailyAverageSales } from '../../base/utils/apiUtil';
import CustomAlert from '../../base/CustomAlert/CustomAlert';
import { getCustomAlert } from '../../base/utils/CustomAlertUtil';
import DailyAverageSalesPopUp from './DailyAverageSalesPopUp';
import UpdateCommonComponent from '../CommonComponents/UpdateCommonComponent';

const UpdateDailyAverageSales = ({ product, closeModal, getData }) => {
  const [productDetail, setProductDetail] = useState({
    das: product.dailyAverageSales,
    addBuffer: product.addBuffer,
    stockBuffer: product.stockBuffer,
  });

  const buildData = async () => {
    try {
      if (isNaN(productDetail.das) || isNaN(productDetail.addBuffer)) {
        CustomAlert('error', 'Daily Average Sale or Buffer can only be a Number');
      } else {
        const reqBody = {
          family: product.family,
          subFamily: product.subFamily,
          country: product.country,
          store: product.store,
          dailyAverageSales: productDetail.das,
          addBuffer: productDetail.addBuffer,
        };
        await onUpdateDailyAverageSales(reqBody, closeModal, getData).catch();
      }
    } catch (error) {
      getCustomAlert(error);
    }
  };

  return (
    <>
      <UpdateCommonComponent
        PopUpComponent={DailyAverageSalesPopUp}
        product={product}
        productDetail={productDetail}
        setProductDetail={setProductDetail}
        buildData = {buildData}
        closeModal = {closeModal}
        bufferFlag = {false}
      />
    </>
  );
};

export default UpdateDailyAverageSales;

import { React, useEffect, useState } from 'react';
import DailyAveragegSales from '../DailyAverageSales/DailyAverageSales';
import ForcedCategory from '../ForcedCategory/ForcedCategory';
import StockBuffer from '../StockBuffer/StockBuffer';
import './MainScreen.css';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import carrefourWhiteLogo from '../../assets/svg/carrefourLogoWhite.png';
import { useMsal } from '@azure/msal-react';
import {
  getWindowDimensions,
  countryJson,
  getCountries,
  userHaveReadAccessOnly,
} from '../../base/utils/util';
import VerticalNavbar from './VerticalNavbar';
import UserDetails from './UserDetails';
import ItemMaster from '../ItemMaster/ItemMaster';
import ManualStock from '../ManualStock/ManualStock';

function MainScreen({ userName, profileImg, Country = 'Country',refreshUserData }) {
  const { instance, accounts } = useMsal();
  const [getViewPort, setViewPort] = useState({ width: 1800, height: '' });
  const [UFlag, setUFlag] = useState(false);
  const [country, setCountry] = useState(countryJson.country);
  const haveReadAccessOnly = userHaveReadAccessOnly();

  const countries = getCountries();


  useEffect(() => {
    const currentCountryList = getCountries();
    const currentCountry = currentCountryList[0];
    countryJson.country = currentCountry;
    if (country==='Country') {
      setCountry(currentCountry);
    }
  }, [country,refreshUserData]);

  const [flag, setFlag] = useState({
    IFlag: true,
    DASFlag: false,
    FCFlag: false,
    SBFlag: false,
    ManualStockFlag: false,
    LogoutFlag: false,
  });

  async function logout() {
    localStorage.clear();
    await instance.logoutRedirect({
      account: accounts[0],
      postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    });
  }
  const resize = ({ width, height }) => {
    setViewPort({ width, height });
  };

  useEffect(() => {
    resize(getWindowDimensions());
    window.addEventListener('resize', () => {
      resize(getWindowDimensions());
    });
  }, []);

  return (
    <>
      <div
        className="mainScreen_navbar"
        style={{
          flexDirection: `${getViewPort.width <= 1000 ? 'row-reverse' : 'row'}`,
        }}
      >
        <div className="logo">
          <img alt="Carrefour-Logo" height={'50px'} src={carrefourWhiteLogo} />
          {getViewPort.width > 1000 && (
            <h3
              style={{
                color: '#fff',
                fontFamily: 'Montserrat',
                whiteSpace: 'nowrap',
              }}
            >
              Stock Management
            </h3>
          )}
        </div>
        {getViewPort.width > 1000 ? (
          <div className="home__div">
            <div className="navbar_menu_div">
              <button
                id="iFlagBtn"
                className={`btn ${flag.IFlag ? 'tabClicked' : 'tablinks'}`}
                onClick={() => setFlag({ IFlag: true })}
              >
                Item Master
              </button>

              <span style={{color: '#fff', fontStyle: 'bold'}} >|</span>

              <button
                id="dasFlagBtn"
                className={`btn ${flag.DASFlag ? 'tabClicked' : 'tablinks'}`}
                onClick={() => setFlag({ DASFlag: true })}
              >
                Daily Average Sales
              </button>

              <span style={{color: '#fff', fontStyle: 'bold'}} >|</span>

              <button
                id="SBFlagBtn"
                className={`btn ${flag.SBFlag ? 'tabClicked' : 'tablinks'}`}
                onClick={() => setFlag({ SBFlag: true })}
              >
                Min Stock Buffer
              </button>

              <span style={{color: '#fff', fontStyle: 'bold'}} >|</span>

              <button
                id="fcFlagBtn"
                className={`btn ${flag.FCFlag ? 'tabClicked' : 'tablinks'}`}
                onClick={() => setFlag({ FCFlag: true })}
              >
                Forced Category
              </button>

              <span style={{color: '#fff', fontStyle: 'bold'}} >|</span>

              <button
                id="manualStockFlagBtn"
                className={`btn ${flag.ManualStockFlag ? 'tabClicked' : 'tablinks'}`}
                onClick={() => setFlag({ ManualStockFlag: true })}
              >
                Manual Stock
              </button>
            </div>

            <div
              className={`navbar_user_div ${
                haveReadAccessOnly ? 'null' : 'navbar_user_div_read_only'
              }`}
            >
              {countries.length > 1 ? (
                <div>
                  <Box sx={{ minWidth: 120, maxWidth: 150 }}>
                    <FormControl fullWidth>
                      <Select
                        style={{ color: 'white', fontFamily: 'Montserrat' }}
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                      >
                        <MenuItem value="Country">{Country}</MenuItem>
                        {countries.map((c) => (
                          <MenuItem style={{ fontFamily: 'Montserrat' }} key={c} value={c}>
                            {c}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              ) : null}

              <button id="FlagBtn" className={`btn tablinks`} onClick={() => setUFlag(!UFlag)}>
                <img
                  width="50px"
                  style={{ borderRadius: '100%' }}
                  src={profileImg}
                  alt="UserImg"
                ></img>
              </button>
            </div>
          </div>
        ) : (
          <VerticalNavbar setFlag={setFlag} logout={logout} />
        )}
      </div>

      {UFlag && <UserDetails userName={userName} logout={logout} setUFlag={setUFlag} />}
      {flag.IFlag && <ItemMaster country={country} />}
      {flag.DASFlag && <DailyAveragegSales country={country} />}
      {flag.FCFlag && <ForcedCategory country={country} />}
      {flag.SBFlag && <StockBuffer country={country} />}
      {flag.ManualStockFlag && <ManualStock country={country} />}
    </>
  );
}

export default MainScreen;

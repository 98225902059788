
import CustomAlert from "../CustomAlert/CustomAlert";

export function getCustomAlert(status,message){
  if (status==='error') {
      status = getErrorStatusType(message);
  }
    switch (status) {
      case 'network_error':
        CustomAlert('error', message.message);
        break;
      case 'response_error':
        CustomAlert('error', message.response?.data?.error?.[0]?.message);
        break;
  
      case 'success':
        CustomAlert('success', message.data.data);
        break;
  
      case 'warn':
        CustomAlert('warn',message);
        break;
    
      default:
        CustomAlert('warn',message);
        break;
    }
  }

  function getErrorStatusType(message) {
    if (message.response?.data?.error?.[0]?.message) {
      return 'response_error';
    }
    return 'network_error';
  }
import React from 'react';
import '../Table.css';
import UpdateDailyAverageSales from './UpdateDailyAverageSales';
import TableCommonComponent from '../CommonComponents/TableCommonComponent';

const DASTable = ({ data, getData }) => {
  const headersList = ['Store', 'Daily Average Sales', 'Add Buffer'];

  return (
    <>
      <TableCommonComponent
        data={data}
        getData={getData}
        fileNamePrefix={'DailyAverageSales'}
        UpdateComponent={UpdateDailyAverageSales}
        headersList={headersList}
      />
    </>
  );
};

export default DASTable;

import React, { useState } from 'react';
import axios from '../../base/utils/CustomAxios';
import CustomAlert from '../../base/CustomAlert/CustomAlert';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ItemComp from '../../Components/ItemsComp/ItemComp';
import { userHaveReadAccessOnly } from '../../base/utils/util';
import Box from '@mui/material/Box';
import '../Screens.css';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getCustomAlert } from '../../base/utils/CustomAlertUtil';
import BulkUpdateItemMaster from '../../Components/ItemsComp/BulkUpdateItemMaster';

const ItemMaster = ({ country }) => {
  const [loader, setLoader] = useState(false);
  const [result, setResult] = useState('');
  const [productCode, setProductCode] = useState('');
  const [openBulkUpdate, setOpenBulkUpdate] = useState(false);

  const haveReadAccessOnly = userHaveReadAccessOnly();

  async function getData() {
    try {
      setResult('');
      setLoader(true);
      if (productCode === '' || country === 'Country') {
        CustomAlert('error', 'Please Enter ProductCode And Choose a Country');
      } else if (isNaN(productCode)) {
        CustomAlert('error', 'ProductCode can only be a number');
      } else {
        const res = await axios.get(
          `stocks/item-master/?productCode=${productCode}&&country=${country}`
        );
        if (res.data.success) {
          setResult(res.data.data);
        }
      }
      setLoader(false);
    } catch (error) {
      setResult('');
      getCustomAlert('error', error);
      setLoader(false);
    }
  }

  return (
    <>
      <div className="action_outer_div">
        <div className="action_buttons_outer">
          <div className="action_buttons_inner">
            <input
              id="input_1"
              className="input_1"
              name="productCode"
              value={productCode}
              placeholder="Product Code"
              onChange={(e) => setProductCode(e.target.value)}
            />

            <Button
              id="getDataBtn"
              onClick={getData.bind()}
              variant="contained"
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
            {haveReadAccessOnly === false ? (
              <Button
                onClick={() => {
                  setOpenBulkUpdate(true);
                }}
                variant="contained"
                startIcon={<FileUploadIcon />}
              >
                Bulk Update
              </Button>
            ) : null}
          </div>
        </div>
        {openBulkUpdate && (
          <div className="open_add_inner_div">
            <BulkUpdateItemMaster
              setOpenBulkUpdate={setOpenBulkUpdate}
              country={country}
              style={{ position: 'absolute' }}
            />
          </div>
        )}

        {result?.length > 0 ? (
          <ItemComp country={country} data={result} getData={getData} />
        ) : (
          loader && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <CircularProgress />
            </Box>
          )
        )}
      </div>
    </>
  );
};

export default ItemMaster;

import React, { useState } from 'react';
import '../Table.css';
import { onUpdateFCData } from '../../base/utils/apiUtil';
import {
  getStyledTableCell,
  getStyledTableRow,
  userHaveReadAccessOnly,
} from '../../base/utils/util';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CsvDownloader from 'react-csv-downloader';
import DownloadIcon from '@mui/icons-material/Download';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

const ForcedCategoryTable = ({ data, getData }) => {
  const [filterData, setFilteredData] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const fileName = `ForcedCategory_${data[0].country}`;

  const haveReadAccessOnly = userHaveReadAccessOnly();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const StyledTableCell = getStyledTableCell();
  const StyledTableRow = getStyledTableRow();

  return (
    <>
      <div className="table-div">
        <div className="pos_searchbox">
          <input
            id="familyInput"
            name="searchFamily"
            placeholder="Search Family"
            onChange={(e) =>
              setFilteredData(
                data.filter((ele) => {
                  return ele.family === e.target.value;
                })
              )
            }
          />
          <div className="div_file_download">
            {data?.length > 0 && (
              <CsvDownloader
                filename={fileName}
                extension=".csv"
                separator=";"
                datas={data}
                text="DOWNLOAD"
              >
                <Button variant="contained" size="small" startIcon={<DownloadIcon />}>
                  Download
                </Button>
              </CsvDownloader>
            )}
          </div>
        </div>
        <div className="table_inner_div">
          <div id="table">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 450 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell
                        sx={{
                          fontFamily: 'Montserrat!important',
                          fontWeight: '600!important',
                          fontSize: '15px',
                        }}
                        align="center"
                      >
                        Family
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          fontFamily: 'Montserrat!important',
                          fontWeight: '600!important',
                          fontSize: '15px',
                        }}
                        align="center"
                      >
                        Sub Family
                      </StyledTableCell>
                      {haveReadAccessOnly === false ? (
                        <StyledTableCell
                          sx={{
                            fontFamily: 'Montserrat!important',
                            fontWeight: '600!important',
                            fontSize: '15px',
                          }}
                          align="center"
                        >
                          Delete
                        </StyledTableCell>
                      ) : null}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {filterData.length > 0
                      ? filterData &&
                        filterData.length > 0 &&
                        filterData.map((product) => {
                          return (
                            <StyledTableRow key={product.id}>
                              <StyledTableCell align="center">{product.family}</StyledTableCell>
                              <StyledTableCell align="center">{product.subFamily}</StyledTableCell>
                              {haveReadAccessOnly === false ? (
                                <StyledTableCell align="center">
                                  <Button
                                    id="updateFCDataBtn"
                                    onClick={onUpdateFCData.bind(this,product,getData, false)}
                                    variant="outlined"
                                    size="small"
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </StyledTableCell>
                              ) : null}
                            </StyledTableRow>
                          );
                        })
                      : data &&
                        data.length > 0 &&
                        data
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((product) => {
                            return (
                              <StyledTableRow key={product.id}>
                                <StyledTableCell align="center">{product.family}</StyledTableCell>
                                <StyledTableCell align="center">
                                  {product.subFamily}
                                </StyledTableCell>
                                {haveReadAccessOnly === false ? (
                                  <StyledTableCell align="center">
                                    <Button
                                      id="updateFCDataBtn"
                                      onClick={onUpdateFCData.bind(this,product,getData, false)}
                                      variant="outlined"
                                      size="small"
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </StyledTableCell>
                                ) : null}
                              </StyledTableRow>
                            );
                          })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[30, 50, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForcedCategoryTable;

import React, { useState } from 'react';
import '../Table.css';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import CsvDownloader from 'react-csv-downloader';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import { getStyledTableCell, getStyledTableRow, userHaveReadAccessOnly } from '../../base/utils/util';
import TableCommonRowComponent from './TableCommonRowComponent';

const TableCommonComponent = ({ data, getData, fileNamePrefix, UpdateComponent, headersList }) => {
  const [filterData, setFilteredData] = useState('');
  const [openUpdate, setOpenUpdate] = useState(false);
  const [updateItem, setUpdateItem] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const fileName = `${fileNamePrefix}_${data[0].country}`;

  const haveReadAccessOnly = userHaveReadAccessOnly();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const StyledTableCell = getStyledTableCell();

  const StyledTableRow = getStyledTableRow();

  return (
    <>
      <div className="table-div">
        <div className="pos_searchbox">
          <div>
            <input
              id="storeInput"
              name="searchStore"
              placeholder="Search Store"
              onChange={(e) => setFilteredData(data.filter((ele) => ele.store === e.target.value))}
            />
          </div>
          <div className="div_file_download">
            {data?.length > 0 && (
              <CsvDownloader
                filename={fileName}
                extension=".csv"
                separator=";"
                datas={data}
                text="DOWNLOAD"
              >
                <Button variant="contained" size="small" startIcon={<DownloadIcon />}>
                  Download
                </Button>
              </CsvDownloader>
            )}
          </div>
        </div>
        <div className="table_inner_div">
          {openUpdate && (
            <div className="open_popup">
              <UpdateComponent
                getData={getData}
                product={updateItem}
                closeModal={setOpenUpdate}
                style={{ position: 'absolute' }}
              />
            </div>
          )}

          <div id="table">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      {headersList.length > 0 &&
                        headersList.map((header) => {
                          return (
                            <StyledTableCell
                              key={header}
                              sx={{
                                fontFamily: 'Montserrat!important',
                                fontWeight: '600!important',
                                fontSize: '15px',
                              }}
                              align="center"
                            >
                              {header}
                            </StyledTableCell>
                          );
                        })}
                      {haveReadAccessOnly === false ? (
                        <StyledTableCell
                          sx={{
                            fontFamily: 'Montserrat!important',
                            fontWeight: '600!important',
                            fontSize: '15px',
                          }}
                          align="center"
                        >
                          Edit
                        </StyledTableCell>
                      ) : null}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {filterData.length > 0
                      ? filterData &&
                        filterData.length > 0 &&
                        filterData.map((product, index) => {
                          return (
                            <TableCommonRowComponent
                              key={index}
                              product={product}
                              fileNamePrefix={fileNamePrefix}
                              haveReadAccessOnly={haveReadAccessOnly}
                              openUpdate={openUpdate}
                              setOpenUpdate={setOpenUpdate}
                              setUpdateItem={setUpdateItem}
                            />
                          );
                        })
                      : data &&
                        data.length > 0 &&
                        data
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((product, index) => {
                            return (
                              <TableCommonRowComponent
                                key={index}
                                product={product}
                                fileNamePrefix={fileNamePrefix}
                                haveReadAccessOnly={haveReadAccessOnly}
                                openUpdate={openUpdate}
                                setOpenUpdate={setOpenUpdate}
                                setUpdateItem={setUpdateItem}
                              />
                            );
                          })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[100, 200, 500]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableCommonComponent;

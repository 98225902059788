import React from 'react';
import '../Update.css';

const StockBufferPopUp = ({ flag = false, productDetail, setProductDetail }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: `${flag ? '70px' : '30px'}` }}>
          <div className="div_style">
            <label>Stock Buffer : </label>
            <input
              id="buffer_input"
              className="input_update"
              type="text"
              name="stock_buffer"
              value={productDetail.stockBuffer}
              onChange={(e) => setProductDetail({ ...productDetail, stockBuffer: e.target.value })}
            />
          </div>
        </div>
      );
}

export default StockBufferPopUp
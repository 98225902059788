import { graphConfig } from './authConfig';

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
export async function getUserPhoto(accessToken, userPrincipalName) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
    'Content-Type': 'image/jpg',
  };

  return fetch(
    'https://graph.microsoft.com/v1.0' + '/users/' + userPrincipalName + '/photo/$value',
    options
  )
    .then((response) => {
      if (response.status === 200) return response.blob();
      else return null;
    })
    .catch((error) => console.log(error));
}
export async function getAllUser(accessToken, userPrincipalName) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  let nextlink = 'https://graph.microsoft.com/v1.0/users';
  let array = [];
  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
    'Content-Type': 'application/json',
  };
  while (nextlink !== undefined && nextlink !== null && nextlink !== '') {
    let data = await fetch(nextlink, options)
      .then((response) => {
        if (response.status === 200) return response;
        else return null;
      })
      .catch((error) => console.log(error));
    let newdata = await data.json();

    nextlink = newdata['@odata.nextLink'];
  }
  return array;
}

import React from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import microsoftLogo from "../Images/microsoftlogo.png";
import mafLogo from "../Images/MAF_logo.png";
import "./login.css";

const Login = () => {
  const { instance, accounts, inProgress } = useMsal();

  const loginRedirect = async () => {
    if (accounts.length <= 0 && inProgress === InteractionStatus.None) {
      await instance.loginRedirect().catch(error => {
        // Handle any potential errors from the promise
        console.error(error);
      });
    }
  };

  return (
    <div>
      <div className="login_outer_div">
        <div style={{ maxWidth: "650px" }}>
          <div className="login_heading">Stock Dashboard</div>
          <div className="login_inner_div">
            <div className="login_outer_card">
              <div>
                <div className="login_subHeading">Sign in to your account</div>
                <div id="loginBtn" className="login_button" onClick={() => loginRedirect()}>
                  <img
                    style={{ marginRight: "20px" }}
                    height="30px"
                    src={microsoftLogo}
                    alt="microsoft_logo"
                  />
                  Continue with Microsoft
                </div>
              </div>

              <div>
                <div className="login_inner_card">
                  <img
                    className="login_footer"
                    width="150px"
                    src={mafLogo}
                    alt="MAF_LOGO"
                  />
                  A Majid Al-Futtaim Company
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;

import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (req) => {
    if (localStorage.getItem('authToken')) {
      req.headers['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`;
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);


instance.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      return res;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

if (localStorage.getItem('authToken')) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`;
}

export default instance;

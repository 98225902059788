/******************************************* Common Utils *************************************************/

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import CustomAlert from '../CustomAlert/CustomAlert';

export function getCountries() {
  if (localStorage.getItem('countries')) {
    return localStorage.getItem('countries').split(',');
  }
  return [];
}

const countries = getCountries();

export const countryJson = {
  country: countries.length === 1 ? countries[0] : 'Country',
};

export const setInitials = (props) => {
  props.map((func) => func(''));
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function userHaveReadAccessOnly() {
  if (localStorage.getItem('roleName')) {
    const role = localStorage.getItem('roleName');
    if (role === 'Country Supervisor' || role === 'Store Supervisor') {
      return true;
    }
  }
  return false;
}

export function userisAdmin() {
  if (localStorage.getItem('roleName')) {
    const role = localStorage.getItem('roleName');
    if (role === 'Stock Admin') {
      return true;
    }
  }
  return false;
}

/************************************************* BULK UPDATE FUNCTIONS START ************************************************/

export const checkValidUpload = (allStores, stores,country, CustomAlert) => {
  if (!allStores && stores === '') {
    CustomAlert('error', 'Please select all stores or enter stores you want to update');
    return false;
  } else if (country === 'Country') {
    CustomAlert('error', 'Please select country');
    return false;
  }
  return true;
};

export const handleFileUpload = (
  urlPath,
  event,
  fileChangeProp
) => {
  if (checkValidUpload(fileChangeProp.allStores, fileChangeProp.stores, fileChangeProp.country, CustomAlert)) {
    if (event?.target?.files[0]) {
      const file = event.target.files[0];
      CustomAlert('warn', 'File uploaded, Processing...Please wait');
      fileChangeProp.setLoader(true);
      fileChangeProp.onBulkUpdateData(urlPath,file, fileChangeProp.allStores, fileChangeProp.stores,fileChangeProp.country, fileChangeProp.setLoader, fileChangeProp.setOpenBulkUpdate);
    } else {
      CustomAlert('error', 'Please select a file');
    }
  }
};

/************************************************* BULK UPDATE FUNCTIONS END ************************************************/

const bgcolor = grey[200];

export function getStyledTableCell() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: bgcolor,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return StyledTableCell;
}

export function getStyledTableRow() {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child StyledTableCell, &:last-child th': {
      border: 0,
    },
  }));
  return StyledTableRow;
}

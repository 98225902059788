import React, { useState } from 'react';
import { onBulkUpdateData } from '../../base/utils/apiUtil';
import { handleFileUpload } from '../../base/utils/util';
import BulkUpdateCommonComponent from '../CommonComponents/BulkUpdateCommonComponent';

const BulkUpdateDailyAverageSales = ({ setOpenBulkUpdate, country }) => {
  const [loader, setLoader] = useState(false);
  const [stores, setStores] = useState('');
  const [allStores, setAllStores] = useState(false);

  const fileChangeProp = {
    onBulkUpdateData:onBulkUpdateData,
    allStores: allStores,
    stores: stores,
    country: country,
    setLoader: setLoader,
    setOpenBulkUpdate: setOpenBulkUpdate
  }

  const handleDasFileChange = (event) => {
    handleFileUpload('daily-average-sales',event,fileChangeProp);
  };

  return (
    <BulkUpdateCommonComponent
      loader={loader}
      stores={stores}
      allStores={allStores}
      setStores= {setStores}
      setAllStores={setAllStores}
      setOpenBulkUpdate={setOpenBulkUpdate}
      label1={'DAS'}
      label2={''}
      uploadMethod1={handleDasFileChange}
      uploadMethod2={undefined}
    />
  );
};

export default BulkUpdateDailyAverageSales;

import React from 'react';
import '../Table.css';
import UpdateStockBuffer from './UpdateStockBuffer';
import TableCommonComponent from '../CommonComponents/TableCommonComponent';

const StockBufferTable = ({ data, getData }) => {
  const headersList = ['Store', 'Stock Buffer'];
  return (
    <>
      <TableCommonComponent
        data={data}
        getData={getData}
        fileNamePrefix={'StockBuffer'}
        UpdateComponent={UpdateStockBuffer}
        headersList={headersList}
      />
    </>
  );
};

export default StockBufferTable;

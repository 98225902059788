import React from 'react';
import '../Update.css';

const DailyAverageSalesPopUp = ({ flag = false, productDetail, setProductDetail }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: `${flag ? '70px' : '30px'}` }}>
      <div className="div_style">
        <label>Daily Avg Sales : </label>
        <input
          id="das_input"
          className="input_update"
          type="text"
          name="das"
          value={productDetail.das}
          onChange={(e) => setProductDetail({ ...productDetail, das: e.target.value })}
        />
      </div>
      <div className="div_style">
        <label>Add Buffer : </label>
        <input
          id="buffer_input"
          className="input_update"
          type="text"
          name="das_addBuffer"
          value={productDetail.addBuffer}
          onChange={(e) => setProductDetail({ ...productDetail, addBuffer: e.target.value })}
        />
      </div>
    </div>
  );
};

export default DailyAverageSalesPopUp;

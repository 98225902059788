import React from 'react';
import TableCell from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { userHaveReadAccessOnly } from '../../base/utils/util';

const ItemTable = ({
  item,
  setUpdateItem,
  setOpenEditBox,
  setCurrentStatusBox,
  getCurrentStockData,
}) => {
  const haveReadAccessOnly = userHaveReadAccessOnly();
  return (
    <>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.store}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.itemStatus}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.isFood ? 'Food' : 'Non Food'}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.dailyAverageSales}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.osmBuffer}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.minStockBuffer}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.daysExclude}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        <Button
          id="currentStockDetailsBtn"
          onClick={() => {
            getCurrentStockData(item.store);
            setCurrentStatusBox(true);
          }}
          variant="outlined"
          size="small"
        >
          <ArrowDropDownCircleIcon />
        </Button>
      </TableCell>

      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.defaultStatus === 'notSpecified' ? 'Not Specified' : 'Force In Stock'}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.currentStatus === 'notSpecified' ? 'Not Specified' : 'Force In Stock'}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.oosSource ==='' ? 'N/A' : item.oosSource}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.IsStockTransfer ? 'Yes' : 'No'}
      </TableCell>
      
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.lastSale === '0' ? 'No Sale' : item.lastSale}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.lastReceive === '0' ? 'No Stock Received' : item.lastReceive}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.deliveryCode}
      </TableCell>
      <TableCell
        sx={{
          fontFamily: 'Montserrat,Open Sans',
          fontWeight: '400',
          lineHeight: '1.45',
          fontSize: '12px',
        }}
        align="center"
      >
        {item.isDarkActive ? 'Yes' : 'No'}
      </TableCell>
      {haveReadAccessOnly === false ? (
        <TableCell
          sx={{ fontFamily: 'Montserrat,Open Sans', fontWeight: '400', lineHeight: '1.45' }}
          align="center"
        >
          <Button
            id="updateBtn"
            onClick={() => {
              setUpdateItem(item);
              setOpenEditBox(true);
            }}
            variant="outlined"
            size="small"
          >
            <EditIcon />
          </Button>
        </TableCell>
      ) : null}
    </>
  );
};

export default ItemTable;

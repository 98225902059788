import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import '../Screens.css';
import StockBufferTable from '../../Components/StockBufferComp/StockBufferTable';
import AddStockBuffer from '../../Components/StockBufferComp/AddStockBuffer';
import BulkUpdateStockBuffer from '../../Components/StockBufferComp/BulkUpdateStockBuffer';
import ScreenCommonComponent from '../ScreenCommonComponent';
const StockBuffer = ({ country }) => {
  return (
    <>
      <ScreenCommonComponent
        country={country}
        urlPath={'stock-buffer'}
        AddComponent={AddStockBuffer}
        BulkUpdateComponent={BulkUpdateStockBuffer}
        TableComponent={StockBufferTable}
      />
    </>
  );
};

export default StockBuffer;

import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import '../Screens.css';
import DailyAverageSalesTable from '../../Components/DailyAverageSalesComp/DailyAverageSalesTable';
import AddDailyAverageSales from '../../Components/DailyAverageSalesComp/AddDailyAverageSales';
import BulkUpdateDailyAverageSales from '../../Components/DailyAverageSalesComp/BulkUpdateDailyAverageSales';
import ScreenCommonComponent from '../ScreenCommonComponent';

const DailyAverageSales = ({ country }) => {
  return (
    <>
      <ScreenCommonComponent
        country={country}
        urlPath={'daily-average-sales'}
        AddComponent={AddDailyAverageSales}
        BulkUpdateComponent={BulkUpdateDailyAverageSales}
        TableComponent={DailyAverageSalesTable}
      />
    </>
  );
};

export default DailyAverageSales;

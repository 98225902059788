import React from 'react';
import '../Update.css';
import {userisAdmin} from '../../base/utils/util';
import Tooltip from '@mui/material/Tooltip';

const CurrentStockDetails = ({item,osmRes,setCurrentStatusBox}) => {

  var modifiedAt = item.modifiedAt.length > 16 ? item.modifiedAt.substring(0,16):item.modifiedAt;

    return (
        <div className="main_outer_div">
          <div className="main_inner_div_cs">
            <div className="modalBackground">
              <div className="modalContainer" style={{width:"500px",height:"500px"}}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Tooltip title="Close">
                    <button id='cross_btn' className="cross_button" onClick={() => setCurrentStatusBox(false)}>
                      X
                    </button>
                  </Tooltip>
                </div>
    
                <div className="centerHeader">
                  <h3>Current Stock Details Below</h3>
                </div>
                <div className="itemUpdate_body">
                  <form className="itemUpdate_form">
                    <div className="curr_div_style">
                      <label>Store :</label>
                      <label id="store" className="store_label">
                        {item.pos}
                      </label>
                    </div> 

                    <div className="curr_div_style">
                      <label>Source :</label>
                      <label id="store" className="store_label">
                        {item.source}
                      </label>
                    </div>

                    <div className="curr_div_style">
                      <label>Available QTY :</label>
                      <label id="store" className="store_label">
                        {item.quantity}
                      </label>
                    </div>

                    <div className="curr_div_style">
                      <label>GIMA QTY :</label>
                      <label id="store" className="store_label">
                        {osmRes[0].gimaQuantity}
                      </label>
                    </div>  

                    <div className="curr_div_style">
                      <label>Reserved QTY :</label>
                      <label id="store" className="store_label">
                        {((osmRes[0].availableQuantity + osmRes[0].soldQuantity)  - item.quantity) >= 0 ?((osmRes[0].availableQuantity + osmRes[0].soldQuantity)  - item.quantity) : 'N/A'}
                      </label>
                    </div>

                    <div className="curr_div_style">
                      <label style={{fontSize:'11px', fontStyle:'italic'}}>Reserved Qty = Today's Online & Offline Sale + Reserved Qty</label>
                    </div>                  

                    {userisAdmin() === true ? <div className="curr_div_style">
                      <label>Last Updated At :</label>
                      <label>
                        {modifiedAt}
                      </label>
                    </div> : null}

                    

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    
  )
}

export default CurrentStockDetails
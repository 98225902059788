import React, { useState } from 'react';
import CustomAlert from '../../base/CustomAlert/CustomAlert';
import { getCustomAlert } from '../../base/utils/CustomAlertUtil';
import { handleFileUpload } from '../../base/utils/util';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { onBulkUpdateData, onUpdateManualStock } from '../../base/utils/apiUtil';
import styles from '../CommonComponents/BulkUpdateCommonComponent.module.css'
import UploadFileIcon from '@mui/icons-material/UploadFile';

const AddManualStock = ({ setOpenManualStockAdd, country }) => {
  const [loader, setLoader] = useState(false);
  const [submitBtnToggle, setSubmitBtnToggle] = useState(true);
  const [manualStockProduct, setManualStockProduct] = useState({
    productCode: '',
    store: '',
    allStores: false,
    isExpired: false,
    quantity: 0,
    beginAt: '',
    endAt: '',
  });

  const buildDataWhenSingleItem = async () => {
    try {
      if (isNaN(manualStockProduct.quantity)) {
        CustomAlert('error', 'Quantity can only be a Number');
      } else if (
        manualStockProduct.productCode === '' ||
        (manualStockProduct.store === '' && manualStockProduct.allStores === false) ||
        manualStockProduct.beginAt === '' ||
        manualStockProduct.endAt === ''
      ) {
        CustomAlert('error', 'Details Missing');
      } else {
        const reqBody = {
          productCode: manualStockProduct.productCode,
          store: manualStockProduct.store,
          quantity: manualStockProduct.quantity,
          country: country,
          isExpired: manualStockProduct.isExpired,
          beginAt: manualStockProduct.beginAt,
          endAt: manualStockProduct.endAt,
        };
        await onUpdateManualStock(
          reqBody,
          manualStockProduct.allStores,
          setOpenManualStockAdd,
          setLoader
        );
      }
    } catch (error) {
      setLoader(false);
      getCustomAlert(error);
    }
  };

  const buildDataWhenManualStockFile = (event) => {
    setLoader(true);
    setSubmitBtnToggle(false);
    if (event?.target?.files[0]) {
      const fileChangeProp = {
        onBulkUpdateData: onBulkUpdateData,
        allStores: manualStockProduct.allStores,
        store: '0',
        country: country,
        setLoader: setLoader,
        setOpenBulkUpdate: setOpenManualStockAdd,
      };
      handleFileUpload('manual-stock', event, fileChangeProp);
      setLoader(false);
      setSubmitBtnToggle(true);
    } else {
      CustomAlert('error', 'File is Missing');
      setLoader(false);
      setSubmitBtnToggle(true);
    }
  };

  return (
    <div className={styles.main_outer_div}>
      <div className={styles.main_inner_div}>
        <div className={styles.modalBackground}>
          <div className={styles.modalContainer}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Close">
                <button className="cross_button" onClick={() => setOpenManualStockAdd(false)}>
                  X
                </button>
              </Tooltip>
            </div>

            <div className={styles.centerHeader}>
              <h3>Manual Stock Update Details</h3>
            </div>
            <div className={styles.itemUpdate_body}>
              <form className={styles.itemUpdate_form}>
                <div className={styles.manual_div_style}>
                  <label>Product Code :</label>
                  <input
                    id="das_input"
                    className={styles.manual_input}
                    type="text"
                    name="productCode"
                    value={manualStockProduct.productCode}
                    onChange={(e) =>
                      setManualStockProduct({ ...manualStockProduct, productCode: e.target.value })
                    }
                  />
                </div>
                {manualStockProduct.allStores === false ? (
                  <div className={styles.manual_div_style}>
                    <label>Stores :</label>
                    <input
                      id="das_input"
                      className={styles.manual_input}
                      type="text"
                      name="store"
                      value={manualStockProduct.store}
                      onChange={(e) => {
                        setManualStockProduct({ ...manualStockProduct, store: e.target.value });
                      }}
                    />
                  </div>
                ) : null}

                <div className={styles.manual_div_style}>
                  <label>All Stores:</label>
                  <input
                    id="allStoresInput"
                    className={styles.manual_stock_allStore_checkbox}
                    type="checkbox"
                    name="das_all_stores"
                    checked={manualStockProduct.allStores}
                    value={manualStockProduct.allStores}
                    onChange={(e) =>
                      setManualStockProduct({
                        ...manualStockProduct,
                        allStores: !manualStockProduct.allStores,
                      })
                    }
                  />
                </div>

                <div className={styles.manual_div_style}>
                  <label>Quantity :</label>
                  <input
                    id="das_input"
                    className={styles.manual_input}
                    type="text"
                    name="quantity"
                    value={manualStockProduct.quantity}
                    onChange={(e) =>
                      setManualStockProduct({ ...manualStockProduct, quantity: e.target.value })
                    }
                  />
                </div>

                <div className={styles.manual_div_style}>
                  <label>Begin At :</label>
                  <input
                    id="das_input"
                    className={styles.manual_input}
                    type="datetime-local"
                    name="beginAt"
                    value={manualStockProduct.beginAt}
                    onChange={(e) =>
                      setManualStockProduct({ ...manualStockProduct, beginAt: e.target.value })
                    }
                  />
                </div>

                <div className={styles.manual_div_style}>
                  <label>End At :</label>
                  <input
                    id="das_input"
                    className={styles.manual_input}
                    type="datetime-local"
                    name="endAt"
                    value={manualStockProduct.endAt}
                    onChange={(e) =>
                      setManualStockProduct({ ...manualStockProduct, endAt: e.target.value })
                    }
                  />
                </div>

                <div className={styles.manual_div_style}>
                  <label>Delete:</label>
                  <input
                    id="allStoresInput"
                    className={styles.manual_stock_allStore_checkbox}
                    type="checkbox"
                    name="das_all_stores"
                    checked={manualStockProduct.isExpired}
                    value={manualStockProduct.isExpired}
                    onChange={(e) =>
                      setManualStockProduct({
                        ...manualStockProduct,
                        isExpired: !manualStockProduct.isExpired,
                      })
                    }
                  />
                </div>

                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                  <label>OR</label>
                </div>

                <div style={{ marginTop: '10px' }} className={styles.manual_div_style}>
                  <label htmlFor="manualStock_file_upload_btn">{`Upload Manual Stock file`}</label>
                  <Button
                    id="manualStock_file_upload_btn"
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                    size="small"
                  >
                    Upload CSV
                    <input
                      id="manualStock_file_upload_btn"
                      type="file"
                      name="file"
                      accept=".csv"
                      hidden
                      onChange={buildDataWhenManualStockFile}
                    />
                  </Button>
                </div>
                {submitBtnToggle ? (
                  <div className={styles.manualStock_footer}>
                    <Button
                      className={styles.manual_stock_submit_btn}
                      id="getDataBtn"
                      onClick={buildDataWhenSingleItem.bind()}
                      variant="contained"
                      size="small"
                    >
                      Submit
                    </Button>
                  </div>
                ) : null}

                {loader && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <CircularProgress />
                  </Box>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddManualStock;

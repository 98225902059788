import React from 'react';
import styles from './BulkUpdateCommonComponent.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';

const BulkUpdateCommonComponent = ({
  loader,
  stores,
  allStores,
  setStores,
  setAllStores,
  setOpenBulkUpdate,
  label1,
  label2,
  uploadMethod1,
  uploadMethod2
}) => {
  return (
    <div className={styles.main_outer_div}>
      <div className={styles.main_inner_div}>
        <div className={styles.modalBackground}>
          <div className={styles.modalContainer}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Close">
                <button
                  id="cross_btn"
                  className={styles.cross_button}
                  onClick={() => setOpenBulkUpdate(false)}
                >
                  X
                </button>
              </Tooltip>
            </div>

            <div className={styles.centerHeader}>
              <h3>Bulk Update Features</h3>
            </div>
            <div className={styles.itemUpdate_body}>
              <form className={styles.itemUpdate_form}>
                <div className={styles.div_style}>
                  <label>Get sample csv files</label>
                  <a
                    href="https://mafretail.atlassian.net/wiki/x/MIC64gE"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button variant="outlined" startIcon={<DownloadIcon />} size="small">
                      Download
                    </Button>
                  </a>
                </div>

                {allStores === false ? (
                  <div className={styles.div_style}>
                    <label>Stores:</label>
                    <input
                      id="storeInput"
                      className={styles.das_store_input}
                      readOnly={allStores}
                      type="text"
                      name="das_stores"
                      value={stores}
                      onChange={(e) => {
                        setStores(e.target.value);
                      }}
                    />
                  </div>
                ) : null}
                <div className={styles.div_style}>
                  <label>All Stores:</label>
                  <input
                  id='allStoresInput'
                    className={styles.das_allStore_checkbox}
                    type="checkbox"
                    name="das_all_stores"
                    checked={allStores}
                    value={allStores}
                    onChange={(e) => {
                      setAllStores(!allStores);
                    }}
                  />
                </div>

                <div className={styles.div_style}>
                  <label htmlFor={`${label1}_file_upload_btn`}>{`Upload ${label1} file`}</label>
                  <Button
                    id={`${label1}_file_upload_btn`}
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                    size="small"
                  >
                    Upload CSV
                    <input
                      id={`${label1}_file_upload_btn`}
                      data-testid={`${label1}_file_upload_btn`}
                      type="file"
                      name="file"
                      accept=".csv"
                      hidden
                      onChange={uploadMethod1}
                    />
                  </Button>
                </div>

                {label2 !== '' ? (
                  <div className={styles.div_style}>
                    <label htmlFor={`${label2}_file_upload_btn`}>{`Upload ${label2} file`}</label>
                    <Button
                      id={`${label2}_file_upload_btn`}
                      data-testid={`${label2}_file_upload_btn`}
                      component="label"
                      variant="outlined"
                      startIcon={<UploadFileIcon />}
                      size="small"
                    >
                      Upload CSV
                      <input
                        id={`${label2}_file_upload_input`}
                        type="file"
                        name="file"
                        accept=".csv"
                        hidden
                        onChange={uploadMethod2}
                      />
                    </Button>
                  </div>
                ) : null}

                {loader && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <CircularProgress />
                  </Box>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkUpdateCommonComponent;

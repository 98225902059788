import { React, useState } from 'react';
import { onUpdateFCData } from '../../base/utils/apiUtil';
import { Button } from '@mui/material';
import '../Update.css';
import Tooltip from '@mui/material/Tooltip';

const AddForcedCategory = ({ country,closeModal, getData }) => {
  const [family, setFamily] = useState('');
  const [subFamily, setSubFamily] = useState('');

  const product = {
    family: family,
    subFamily: subFamily,
    country: country,
  };

  return (
    <div className="main_outer_div">
      <div className="main_inner_div">
        <div className="modalBackground">
          <div className="modalContainer">
            <div style={{ display: 'flex', flexDirection: 'flex-end' }}>
              <Tooltip title="Close">
                <button className="cross_button" onClick={() => closeModal(false)}>
                  X
                </button>
              </Tooltip>
            </div>

            <div className="centerHeader">
              <h3>Add New Forced Category</h3>
            </div>
            <div className="itemUpdate_body">
              <form action="" className="itemUpdate_form">
                <div className="div_style">
                  <label>Family : </label>
                  <input
                    id="family"
                    type="text"
                    name="add_input"
                    className="input_update"
                    value={family}
                    onChange={(e) => {
                      setFamily(e.target.value);
                    }}
                  />
                </div>
                <div className="div_style">
                  <label>SubFamily : </label>
                  <input
                    id="subFamily"
                    type="text"
                    name="add_input"
                    className="input_update"
                    value={subFamily}
                    onChange={(e) => {
                      setSubFamily(e.target.value);
                    }}
                  />
                </div>
                <div className="div_style">
                  <label>Country : </label>
                  <label>{country}</label>
                </div>
              </form>
            </div>
            <div className="footer">
              <Button
                id="updateFcBtn"
                onClick={onUpdateFCData.bind(this, product, getData, true)}
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddForcedCategory;

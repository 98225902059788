import React from 'react';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import './Vertical.css';

const UserDetails = ({ userName, logout, setUFlag }) => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <div className="user_div">
          <span>{userName}</span>
          <span>{localStorage.getItem('roleName')}</span>
          <Button
            id="logOutBtn"
            variant="outlined"
            startIcon={<LogoutIcon />}
            style={{ color: 'white' }}
            onClick={() => logout()}
          >
            LogOut
          </Button>
        </div>
      </div>
    </>
  );
};

export default UserDetails;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CircularProgress from '@mui/material/CircularProgress'; // Added a loader
import axios from '../../base/utils/CustomAxios';
import { getCustomAlert } from '../../base/utils/CustomAlertUtil';
import CustomAlert from '../../base/CustomAlert/CustomAlert';

const GenerateReport = () => {
  const [store, setStore] = useState('');
  const [loader, setLoader] = useState(false);
  const country = 'UAE';

  const handleGenerateReport = async () => {
    if (!store.trim() || !country.trim()) {
      CustomAlert('error', 'Please enter both Store and Country properly.');
      return;
    }

    setLoader(true);

    try {
      const response = await axios.get(`/stocks/report`, {
        params: { pos: store, country },
        responseType: 'blob'
      });

      if (response.status === 200) {
        // Create a temporary link element
        const link = document.createElement('a');

        // Create an object URL for the blob content
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        // Set the download attribute with a filename
        link.href = url;
        link.setAttribute('download', `stock-report-${country}-${store}.csv`);

        // Append the link to the body and simulate a click to trigger the download
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      getCustomAlert('error',error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="action_outer_div">
      <div className="action_buttons_outer">
        <div className="action_buttons_inner">
          <input
            id="countryInput"
            className="input_1"
            name="country"
            value={country}
            readOnly
            aria-label="Country"
          />

          <input
            id="storeInput"
            className="input_2"
            name="store"
            value={store}
            placeholder="Enter Store"
            maxLength={5}
            minLength={1}
            onChange={(e) => setStore(e.target.value)}
            aria-label="Store"
          />

          <Button
            id="generateReportBtn"
            onClick={handleGenerateReport}
            variant="contained"
            startIcon={loader ? <CircularProgress size={20} /> : <FileDownloadIcon />}
            disabled={loader} // Disable button during loading
          >
            {loader ? 'Generating...' : 'Generate Report'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GenerateReport;

import React, { useState } from 'react';
import '../Table.css';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TablePagination from '@mui/material/TablePagination';
import { userHaveReadAccessOnly } from '../../base/utils/util';
import axios from '../../base/utils/CustomAxios';
import { getCustomAlert } from '../../base/utils/CustomAlertUtil';
import ItemTable from './ItemTable';
import UpdateItemMaster from './UpdateItemMaster';
import CurrentStockDetails from './CurrentStockDetails';

const ItemComp = ({ country, data, getData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [filterData, setFilteredData] = useState('');
  const [openEditBox, setOpenEditBox] = useState(false);
  const [currentStatusBox, setCurrentStatusBox] = useState(false);
  const [updateItem, setUpdateItem] = useState();
  const [darkStoreFlag, setDarkStoreFlag] = useState(true);
  const [productTypeFlag, setProductTypeFlag] = useState(true);
  const [atsResult, setAtsResult] = useState('');
  const [osmResult, setOsmResult] = useState('');

  const config = {
    headers: {
      'API-Key': process.env.REACT_APP_ATS_API_URL_KEY
    }
  }

  async function getCurrentStockData(store) {
    let atsRes = null;

    let site = 'maf' + country;
    try {
      setAtsResult('');
      atsRes = await axios.get(
        `${process.env.REACT_APP_ATS_API_URL}final-availability/v1?sku=${
          data[0].productCode
        }&pos=${store}&site=${site.toLocaleLowerCase()}`,config
      );

      if (atsRes.data.success) {
        await getOsmResult(store);
        setAtsResult(atsRes.data.data);
      }
    } catch (error) {
      setAtsResult('');
      getCustomAlert('error', error);
    }
  }

  async function getOsmResult(store) {
    try {
      let osmRes = null;
      osmRes = await axios.get(
        `stocks/current-stock?productCode=${data[0].productCode}&store=${store}&country=${country}`
      );
      if (osmRes.data.success) {
        setOsmResult(osmRes.data.data);
      }
    } catch (error) {
      setOsmResult('');
      getCustomAlert('error', error);
    }
  }

  const itemStatusArray = [
    'None',
    'Active (AC)',
    'Active (AG)',
    'Active (NI)',
    'Inactive (HO)',
    'Inactive (NA)',
    'Inactive (NC)',
    'Inactive (NS)',
    'Inactive (OD)',
    'Inactive (OH)',
    'Inactive (OE)',
  ];

  const handleDarkStoreSort = () => {
    setDarkStoreFlag(!darkStoreFlag);
    if (darkStoreFlag) {
      setFilteredData(data.filter((ele) => ele.isDarkActive === true));
    } else {
      setFilteredData('');
    }
  };

  const handleProductTypeSort = () => {
    setProductTypeFlag(!productTypeFlag);
    if (productTypeFlag) {
      setFilteredData(data.filter((ele) => ele.isFood === false));
    } else {
      setFilteredData('');
    }
  };

  const handleItemStatusSort = (e) => {
    const itemStatusNewVal = e.target.value;
    if (itemStatusNewVal !== 'Active (None)') {
      setFilteredData(data.filter((ele) => ele.itemStatus === itemStatusNewVal));
    } else {
      setFilteredData('');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const haveReadAccessOnly = userHaveReadAccessOnly();

  return (
    <>
      <div style={{ width: '90%', margin: '2% auto' }}>
        <div style={{ width: '100%', minWidth: '500px' }}>
          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '15px',
                      }}
                      align="center"
                    >
                      Barcode
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '15px',
                      }}
                      align="center"
                    >
                      Product Code
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '15px',
                      }}
                      align="center"
                    >
                      Department
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '15px',
                      }}
                      align="center"
                    >
                      Section
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '15px',
                      }}
                      align="center"
                    >
                      Family
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '15px',
                      }}
                      align="center"
                    >
                      Sub Family
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '15px',
                      }}
                      align="center"
                    >
                      Packed Item
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '15px',
                      }}
                      align="center"
                    >
                      Mapped Unit Item
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '15px',
                      }}
                      align="center"
                    >
                      Number of Units
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={data[0].id}>
                    <TableCell sx={{ fontSize: '15px' }} align="center">
                      {data[0].barcode}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }} align="center">
                      {data[0].productCode}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }} align="center">
                      {data[0].department}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }} align="center">
                      {data[0].section}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }} align="center">
                      {data[0].family}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }} align="center">
                      {data[0].subFamily}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }} align="center">
                      {data[0].isItemPack}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }} align="center">
                      {data[0].unitItem === 0 ? 'N/A' : data[0].unitItem}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }} align="center">
                      {data[0].numberOfUnit}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      <div className="table_inner_div">
        {openEditBox && (
          <div className="open_popup">
            <UpdateItemMaster
              item={updateItem}
              getData={getData}
              setOpenEditBox={setOpenEditBox}
              style={{ position: 'absolute' }}
            />
          </div>
        )}

        {currentStatusBox && atsResult !== '' && osmResult !== '' && (
          <div className="open_popup">
            <CurrentStockDetails
              item={atsResult}
              osmRes={osmResult}
              setCurrentStatusBox={setCurrentStatusBox}
              style={{ position: 'absolute' }}
            />
          </div>
        )}
        <div id="table">
          <div className="pos_searchbox">
            <input
              id="storeInput"
              name="searchStore"
              placeholder="Search Store"
              onChange={(e) => setFilteredData(data.filter((ele) => ele.store === e.target.value))}
            />
          </div>
          <Paper sx={{ width: '100%', overflow: 'scroll' }}>
            <TableContainer sx={{ maxHeight: 445 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      Store
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      <span style={{ display: 'flex', justifyContent: 'center' }}>
                        Item Status
                        <Select
                          id="itemStatusSelect"
                          style={{
                            color: 'white',
                            height: '20px',
                            width: '30px',
                            fontFamily: 'Montserrat',
                          }}
                          value=""
                          onChange={handleItemStatusSort}
                        >
                          {itemStatusArray.map((s) => (
                            <MenuItem
                              style={{ fontFamily: 'Montserrat', fontSize: '12px' }}
                              key={s}
                              value={s}
                            >
                              {s}
                            </MenuItem>
                          ))}
                        </Select>
                      </span>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      <span style={{ display: 'flex' }}>
                        Product Type
                        <button
                          id="productTypeBtn"
                          style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                          onClick={() => {
                            handleProductTypeSort();
                          }}
                        >
                          {productTypeFlag ? (
                            <ArrowUpwardIcon style={{ width: '20px' }} />
                          ) : (
                            <ArrowDownwardIcon style={{ width: '20px' }} />
                          )}
                        </button>
                      </span>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      Daily Average Sales
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      OSM Buffer
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      Min Stock Buffer
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      Max days (OOS)
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      Current Stock Details
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      OSM Default Status
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      OSM Current Status
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      OOS Source
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      Stock Active
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      Latest Sale
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      Last Stock Received
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      Delivery Code
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Montserrat!important',
                        fontWeight: '600!important',
                        fontSize: '12px!important',
                      }}
                      align="center"
                    >
                      <span style={{ display: 'flex' }}>
                        Dark Store
                        <button
                          id="darkStoreBtn"
                          style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                          onClick={() => {
                            handleDarkStoreSort();
                          }}
                        >
                          {darkStoreFlag ? (
                            <ArrowUpwardIcon style={{ width: '20px' }} />
                          ) : (
                            <ArrowDownwardIcon style={{ width: '20px' }} />
                          )}
                        </button>
                      </span>
                    </TableCell>
                    {haveReadAccessOnly === false ? (
                      <TableCell
                        sx={{
                          fontFamily: 'Montserrat!important',
                          fontWeight: '600!important',
                          fontSize: '12px!important',
                        }}
                        align="center"
                      >
                        Edit
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterData.length > 0
                    ? filterData &&
                      filterData.length > 0 &&
                      filterData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item) => {
                          return (
                            <TableRow key={item.id}>
                              <ItemTable
                                item={item}
                                setUpdateItem={setUpdateItem}
                                setOpenEditBox={setOpenEditBox}
                                setCurrentStatusBox={setCurrentStatusBox}
                                getCurrentStockData={getCurrentStockData}
                              />
                            </TableRow>
                          );
                        })
                    : data &&
                      data.length > 0 &&
                      data
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item) => {
                          return (
                            <TableRow key={item.id}>
                              <ItemTable
                                item={item}
                                setUpdateItem={setUpdateItem}
                                setOpenEditBox={setOpenEditBox}
                                setCurrentStatusBox={setCurrentStatusBox}
                                getCurrentStockData={getCurrentStockData}
                              />
                            </TableRow>
                          );
                        })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[100, 200, 500]}
              component="div"
              count={data && data.length > 0 ? data.length : filterData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
};

export default ItemComp;

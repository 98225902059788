import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { Slide, ToastContainer } from "react-toastify";
import { ReactComponent as CloseIconButton } from "./assets/svg/closeButton.svg";

const msalInstance = new PublicClientApplication(msalConfig);

const ToastCloseIcon = ({ closeToast }) => {
  return <CloseIconButton onClick={closeToast} className="close-icon" />;
};

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <ToastContainer
      position="top-center"
      transition={Slide}
      icon={false}
      hideProgressBar={false}
      closeButton={ToastCloseIcon}
      autoClose={2000}
    />
    <App />
  </MsalProvider>,

  document.getElementById("root")
);

reportWebVitals();

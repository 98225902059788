import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as FailureIcon } from '../../assets/svg/FailureIcon.svg';
import toastStyles from './CustomAlertStyles';

export const ALERT_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARN: 'warn',
};

const ToastContent = ({ message, Icon }) => {
  const classes = toastStyles();
  return (
    <div className={classes.toastMessageContainer}>
      {Icon && <Icon className={classes.icon} />}
      <div>{message}</div>
    </div>
  );
};

const CustomAlert = (type, message) => {
  let toastType = 'success';
  let toastContent = message;
  let toastOptions = {};

  switch (type) {
    case ALERT_TYPES.ERROR:
      toastType = 'error';
      toastContent = <ToastContent message={message} Icon={FailureIcon} />;
      toastOptions = {
        theme: 'colored',
      };
      break;
    case ALERT_TYPES.WARN:
      toastType = 'warn';
      toastContent = <ToastContent message={message} />;
      break;
    // Add additional cases for other alert types if needed
  }

  return toast[toastType](toastContent, toastOptions);
};

export default CustomAlert;

import React, { useState } from 'react';
import BulkUpdateCommonComponent from '../CommonComponents/BulkUpdateCommonComponent';
import { onBulkUpdateData } from '../../base/utils/apiUtil';
import { handleFileUpload } from '../../base/utils/util';

const BulkUpdateItemMaster = ({ setOpenBulkUpdate,country }) => {
  const [loader, setLoader] = useState(false);
  const [stores, setStores] = useState('');
  const [allStores, setAllStores] = useState(false);

  const fileChangeProp = {
    onBulkUpdateData:onBulkUpdateData,
    allStores: allStores,
    stores: stores,
    country: country,
    setLoader: setLoader,
    setOpenBulkUpdate: setOpenBulkUpdate
  }

  const handleForceInStockFileChange = (event) => {
    handleFileUpload('item-master/forceInStock',event,fileChangeProp);
  };

  const handleProductTypeFileChange = (event) => {
    handleFileUpload('item-master/productTypes',event,fileChangeProp);
  };


  return (
    <BulkUpdateCommonComponent
      loader={loader}
      stores={stores}
      allStores={allStores}
      setStores={setStores}
      setAllStores={setAllStores}
      setOpenBulkUpdate={setOpenBulkUpdate}
      label1={'ForceInStock'}
      label2={'Product Type'}
      uploadMethod1={handleForceInStockFileChange}
      uploadMethod2={handleProductTypeFileChange}
    />
  );
};

export default BulkUpdateItemMaster;

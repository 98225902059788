import React, { useState } from 'react';
import axios from '../base/utils/CustomAxios';
import CustomAlert from '../base/CustomAlert/CustomAlert';
import 'react-toastify/dist/ReactToastify.css';
import './Screens.css';
import { userHaveReadAccessOnly } from '../base/utils/util';
import Button from '@mui/material/Button';
import { getCustomAlert } from '../base/utils/CustomAlertUtil';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const ScreenCommonComponent = ({
  country,
  urlPath,
  AddComponent,
  BulkUpdateComponent,
  TableComponent,
}) => {
  const [family, setFamily] = useState('');
  const [subFamily, setSubFamily] = useState('');
  const [result, setResult] = useState('');
  const [openAdd, setOpenAdd] = useState(false);
  const [openBulkUpdate, setOpenBulkUpdate] = useState(false);
  const [loader, setLoader] = useState(false);

  const haveReadAccessOnly = userHaveReadAccessOnly();

  async function getData() {
    let res = null;
    try {
      setResult('');
      setLoader(true);
      if (country === 'Country' || family === '' || subFamily === '') {
        getCustomAlert('warn', 'Please Enter Family, Sub Family and Choose Country');
      } else if (isNaN(family) || isNaN(subFamily)) {
        getCustomAlert('warn', 'Family, Sub Family can only be a Number');
      } else {
        res = await axios.get(
          `stocks/${urlPath}/?country=${country}&&family=${family}&&subFamily=${subFamily}`
        );
        if (res.data.success) {
          setResult(res.data.data);
        }
      }
      setLoader(false);
    } catch (error) {
      setResult('');
      setLoader(false);
      getCustomAlert('error', error);
    }
  }

  return (
    <>
      <div className="action_outer_div">
        <div className="action_buttons_outer">
          <div className="action_buttons_inner">
            <input
              id="input_1"
              className="input_1"
              label="family"
              value={family}
              placeholder="Family"
              onChange={(e) => setFamily(e.target.value)}
            />
            <input
              id="input_2"
              className="input_2"
              label="subFamily"
              value={subFamily}
              placeholder="Sub Family"
              onChange={(e) => setSubFamily(e.target.value)}
            />
            <Button
              id="getDataBtn"
              onClick={getData.bind()}
              variant="contained"
              startIcon={<SearchIcon />}
            >
              Search
            </Button>

            {haveReadAccessOnly === false ? (
              <Button
                onClick={() => {
                  if (family === '' || subFamily === '' || country === 'Country') {
                    CustomAlert('error', 'Please enter Family, SubFamily and Country');
                  } else {
                    setOpenAdd(true);
                  }
                }}
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            ) : null}

            {haveReadAccessOnly === false ? (
              <Button
                onClick={() => {
                  setOpenBulkUpdate(true);
                }}
                variant="contained"
                startIcon={<FileUploadIcon />}
              >
                Bulk Update
              </Button>
            ) : null}
          </div>
        </div>

        <div className="open_add_outer_div">
          {openAdd && (
            <div className="open_add_inner_div">
              <AddComponent
                family={family}
                subFamily={subFamily}
                country={country}
                getData={getData}
                closeModal={setOpenAdd}
                style={{ position: 'absolute' }}
              />
            </div>
          )}
          {openBulkUpdate && (
            <div className="open_add_inner_div">
              <BulkUpdateComponent
                setOpenBulkUpdate={setOpenBulkUpdate}
                country={country}
                style={{ position: 'absolute' }}
              />
            </div>
          )}
        </div>

        {result?.length > 0 ? (
          <TableComponent data={result} getData={getData} />
        ) : (
          loader && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <CircularProgress />
            </Box>
          )
        )}
      </div>
    </>
  );
};

export default ScreenCommonComponent;
